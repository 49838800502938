import { useEffect, useState } from 'react';
import styles from './stream-feature.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetOneManufacturingOrderQuery } from '../../../../services/manufacturing-orders-api.services';
import { useGetOneOrderQuery } from '../../../../services/orders-api.services';
import { WorkflowModelingStepEnum } from '../../../../enum/workflow-step';
import { ProgressBar, Text } from '@anatoscope/circlestorybook';
import { useAppDispatch } from '../../../../store/hooks';
import { ordersActions } from '../../../../store/orders/orders.reducer';

type props = {
  orderNumber: string;
};

interface StreamingParams {
  host: string;
  port: string;
  path: string;
  password: string;
  encrypt: string;
}

const StreamFeature = ({ orderNumber }: props) => {
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();

  const [streamingParams, setStreamingParams] = useState<StreamingParams>();
  const [displayExportFile, setDisplayExportFile] = useState(false);

  useEffect(() => {
    const css = document.createElement('link');
    css.setAttribute('rel', 'stylesheet');
    css.setAttribute('href', '/js/anatostreamer/static/anatostreamer.bundle.css');
    document.body.appendChild(css);

    const fonts = document.createElement('link');
    fonts.setAttribute('rel', 'stylesheet');
    fonts.setAttribute('href', 'https://fonts.googleapis.com/icon?family=Material+Icons');
    document.body.appendChild(fonts);

    const script = document.createElement('script');
    script.setAttribute('src', '/js/anatostreamer/static/anatostreamer.bundle.js');
    document.body.appendChild(script);

    // Set a callback function to execute when the script is loaded
    script.onload = () => {
      // Code to run after the script is loaded
      if (streamingParams) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const anatostreamer = new AnatoStreamer('streaming-div');
        const config = {
          host: streamingParams.host,
          vnc: {
            port: streamingParams.port,
            path: streamingParams.path,
            passwd: streamingParams.password,
            encrypt: streamingParams.encrypt,
            autoRescale: true, // enable scaleviewport option, default true
            autoQuality: false,
            viewOnly: false,
            retry: 60, // number of connection before connect_failed callback, default 5
            retryInterval: 5000, // interval (ms) between retries, default 1000
            qualityLevel: 5,
            compressionLevel: 5,
            localCursor: true,
            log_level: 'warn', // define the console log level, can be `warn`, `error`, `info` or
            waitingBackground: '#E4E4EC'
          },
          menu: {
            enabled: false,
            // settings: ['vnc-quality', "menu-position"],
            settings: [
              'view-only',
              'scale-mode',
              'vnc-cursor-fallback-policy',
              'vnc-auto-quality',
              'vnc-quality',
              'vnc-compression'
            ]
          },
          onConnectionLost: (error: object, params: object) => {
            console.log('Connection lost', error, params);
          },
          onFocusLostVisual: true
        };
        anatostreamer
          .connect(config)
          .then(() => {
            console.log('Connected');
            setDisplayExportFile(true);
          })
          .catch((error: object) => {
            console.error(error);
          });
        dispatch(ordersActions.setIsStreaming(true));
      }
    };

    return () => {
      // clean up the script when the component is unmounted
      document.body.removeChild(css);
      document.body.removeChild(script);
      document.body.removeChild(fonts);
    };
  }, [streamingParams]);

  // at first, we query ms-orders and skip ms-manufacturing
  const [skipOrderQuery, setSkipOrderQuery] = useState<boolean>(false);
  const [skipManufacturingOrderQuery, setSkipManufacturingOrderQuery] = useState<boolean>(true);

  // we query ms-order => until we reach validation step => then allow query on manufacturing order and skip order
  const { data: order } = useGetOneOrderQuery(orderNumber, {
    pollingInterval: 3000,
    skip: skipOrderQuery
  });

  const { data: manufacturingOrder } = useGetOneManufacturingOrderQuery(orderNumber, {
    pollingInterval: 2000,
    skip: skipManufacturingOrderQuery
  });

  if (
    order?.currentStep &&
    [WorkflowModelingStepEnum.MODELING, WorkflowModelingStepEnum.MODELING_DESIGN].includes(
      order.currentStep as WorkflowModelingStepEnum
    )
  ) {
    setSkipOrderQuery(true);
    setSkipManufacturingOrderQuery(false);
  }

  if (manufacturingOrder?.streamingParameters) {
    setSkipManufacturingOrderQuery(true);
    setStreamingParams(JSON.parse(manufacturingOrder.streamingParameters));
  }

  return (
    <>
      <div className={styles['stream-feature__wait-screen']}>
        {!streamingParams && (
          <>
            <ProgressBar duration={16}>
              <div className={styles['stream-feature__wait-screen__text']}>
                <Text
                  color="primary"
                  label={t('createOrder.streamingFeature.loading.title')}
                  size="s"
                  textAlign="center-align"
                  type="title"
                />
                <Text
                  textAlign="center-align"
                  color="grey"
                  label={t('createOrder.streamingFeature.loading.text')}
                  size="m"
                />
                <Text
                  color="grey"
                  label={t('createOrder.streamingFeature.loading.load')}
                  size="m"
                  textAlign="center-align"
                  className={styles['stream-feature__wait-screen__text__loading']}
                />
              </div>
            </ProgressBar>
          </>
        )}
        {displayExportFile && (
          <div className={styles['stream-feature__wait-screen--text']}>
            {t('createOrder.streamingFeature.export')}
          </div>
        )}
      </div>
      <div id="streaming-div" className={styles['stream-feature__container']}></div>
    </>
  );
};
export default StreamFeature;
