import { useAppSelector } from '../../../../store/hooks';
import { orderSelector } from '../../../../store/orders/orders.selectors';
import StreamFeature from './StreamFeature';

const DesignStep = () => {
  const order = useAppSelector(orderSelector);

  return <StreamFeature orderNumber={order?.orderNumber} />;
};

export default DesignStep;
