import { api } from './api';
import { ManufacturingOrder } from '../models/manufacturing-order';

const prefix_path = '/manufacturing/';

export const manufacturingOrdersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOneManufacturingOrder: builder.query<ManufacturingOrder, string>({
      query: (orderNumber) => {
        return `${prefix_path}orders/${orderNumber}`;
      }
    }),
    endTask: builder.mutation<void, string>({
      query: (orderNumber) => ({
        url: `${prefix_path}orders/${orderNumber}/modeling/endTask`,
        method: 'POST'
      })
    })
  })
});

export const { useGetOneManufacturingOrderQuery, useEndTaskMutation } = manufacturingOrdersApi;
