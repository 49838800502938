import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut as signOutFirebase
} from 'firebase/auth';

import { UserCallback } from '../models/user';
import getAuthFirebase from '../auth/firebase.config';

const signIn = (email: string, password: string) => {
  return signInWithEmailAndPassword(getAuthFirebase(), email, password);
};

const signOut = () => {
  return signOutFirebase(getAuthFirebase());
};

const getAuthState = (callback: UserCallback) => {
  return onAuthStateChanged(getAuthFirebase(), (userFirebase) => {
    callback(userFirebase);
  });
};

const isCurrentUser = () => {
  return !!getAuthFirebase().currentUser;
};

const getClaims = async () => {
  return getAuthFirebase().currentUser?.getIdTokenResult();
};

const isDentistUser = async () => {
  const idTokenResult = await getClaims();
  return !!idTokenResult?.claims?.role && !!idTokenResult?.claims?.clinicId;
};

const getToken = async (forceRefresh = false) => {
  return getAuthFirebase()?.currentUser?.getIdToken(forceRefresh);
};

/**
 * Force the refresh of Firebase token.
 * If we want to get the new claims, use getIdTokenResult() instead.
 */
const forceRefreshToken = () => {
  return getToken(true);
};

export { signIn, getAuthState, signOut, forceRefreshToken, getToken, isCurrentUser, isDentistUser };
