import { Box, Button, TeethMap, Text } from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import styles from './protheses-form.module.scss';
import useForm from '../../../../utils/useForm';
import { useState } from 'react';
import { useGetConnectedUserQuery } from '../../../../services/user.api.services.ts';
import { DentalNotation } from '../../../../enum/user.ts';
import { ColorPropsEnum } from '../../../../enum/color.ts';
import { useAppSelector } from '../../../../store/hooks.tsx';
import { orderSelector } from '../../../../store/orders/orders.selectors.tsx';
import { Order, OrderItemForCreation } from '../../../../models/order.tsx';
import {
  ComponentType,
  PositionKey,
  ToothStratificationTechniqueEnum
} from '../../../../enum/component.ts';
import {
  usePatchOrderItemMutation,
  usePatchOrderMutation,
  useSubmitOrderMutation,
  useValidateOrderMutation
} from '../../../../services/orders-api.services.ts';
import { cursors, getMissingTeeth } from '../../../../utils/map.utils.ts';
import { CircleCadVersionEnum } from '../../../../enum/circle-cad-version.ts';

type Props = {
  nextCallback: () => void;
};
const ProthesesForm = ({ nextCallback }: Props) => {
  const { t } = useTranslation(['order']);
  const [patientMouth, setPatientMouth] = useState<{
    [key: string]: Array<number> | Array<Array<number>>;
  }>({});
  const [error, setError] = useState<boolean>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [familyColorPositions, setFamilyColorPositions] = useState({});

  const { data: connectedUser } = useGetConnectedUserQuery();
  const [patchOrder] = usePatchOrderMutation();
  const [submitOrder] = useSubmitOrderMutation();
  const [validateOrder] = useValidateOrderMutation();
  const [updateOrderItem] = usePatchOrderItemMutation();

  const order = useAppSelector(orderSelector);

  const submit = async (): Promise<void> => {
    if (!patientMouth?.TOOTH?.length) {
      setError(true);
      return;
    }
    if (order?.orderNumber) {
      setIsSaving(true);
      await addDiagnostic();
      await updateOrderItems();
      await submitOrder(order.orderNumber);
      validate();
      setIsSaving(false);
      nextCallback();
    }
  };

  const validate = () => {
    const timeoutId = setTimeout(() => {
      validateOrder({
        orderNumber: order.orderNumber,
        isApproved: true,
        circleCadVersionId: 1,
        circleCadVersion: null,
        circleCadVersionLabel: CircleCadVersionEnum.ALPHA
      });
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  };

  const addDiagnostic = async (): Promise<void> => {
    const tooth = patientMouth?.TOOTH.map((value) => {
      return (value as number[])[0];
    })[0];
    const orderToPatch: Partial<Order> = {
      orderNumber: order?.orderNumber,
      patient: {
        diagnostic: {
          missingTeeth: getMissingTeeth(tooth),
          naturalStumps: patientMouth?.TOOTH[0] as number[]
        }
      }
    };
    await patchOrder(orderToPatch);
  };

  const updateOrderItems = async (): Promise<void> => {
    const item: OrderItemForCreation = {
      tags: [],
      itemComponents: [
        {
          teethPositions: patientMouth?.TOOTH[0] as PositionKey[],
          componentType: ComponentType.TOOTH,
          toothStratificationTechnique: ToothStratificationTechniqueEnum.FULL,
          material: { id: 1 },
          shape: { id: 1 },
          shade: { id: 1 }
        }
      ]
    };
    await updateOrderItem({ orderNumber: order.orderNumber, id: order?.items?.[0].id, item });
  };

  const getBubble = () => {
    if (patientMouth?.TOOTH?.length) {
      return {
        [(patientMouth.TOOTH[0] as number[])[0]]: {
          type: 'product',
          size: 'small',
          backgroundColor: 'grey-100',
          url: 'https://storage.googleapis.com/public-circle-dental/images/products/Fixed/crown.svg',
          color: 'family-fixed'
        }
      };
    }
  };

  const { handleSubmit } = useForm({}, submit);

  const handleSelectTooth = (selectedTooth: number): void => {
    setError(false);
    setPatientMouth({ TOOTH: [[selectedTooth]], STUMP: [[selectedTooth]] });
    setFamilyColorPositions({ 'family-fixed': [selectedTooth] });
  };

  return (
    <div className={styles['protheses-form__box']}>
      <form onSubmit={handleSubmit} className={styles['protheses-form__box__form']}>
        <div className={styles['protheses-form__box__form__content']}>
          <div className={styles['protheses-form__box__form__content__mouth']}>
            <Box className={styles['protheses-form__box__intro']}>
              {!error && <Text label={t('createOrder.mouth.intro')} />}
              {error && <Text label={t('createOrder.mouth.intro')} color={ColorPropsEnum.DANGER} />}
            </Box>
            <TeethMap
              className={styles['protheses-form__box__form__content__mouth__teeth-map']}
              onClick={(tooth: number) => handleSelectTooth(+tooth)}
              patientMouth={patientMouth}
              notation={connectedUser ? connectedUser?.dentalNotation : DentalNotation.ISO}
              cursors={cursors}
              bubbles={getBubble()}
              isStrokeTeeth={true}
              lineAndNumberColors={familyColorPositions}
            />
          </div>
        </div>
        <div className={styles['protheses-form__box__form__button']}>
          <Button
            label={t('action.next', { ns: 'common' })}
            type="submit"
            category="primary"
            variant="success"
            iconRight="fa-chevron-right"
            isLoading={isSaving}
          />
        </div>
      </form>
    </div>
  );
};
export default ProthesesForm;
